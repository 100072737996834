<template>
  <v-container class="signup-or-login-form">
    <v-row class="tw-mb-14">
      <v-col>
        <h2 class="tw-text-lg tw-font-semibold tw-text-charcoal">
          Login or Sign up
        </h2>
        <p class="tw-mb-0 tw-text-light-grey tw-text-base tw-leading-22">
          To start planning your next adventure.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-form @submit.prevent="progressSignupOrLogin">
          <validation-observer ref="observer" v-slot="{}">
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <j-text-field
                    color="secondary"
                    v-model="email"
                    type="email"
                    rounded
                    outlined
                    required
                    :hide-details="errors.length === 0"
                    placeholder="Enter email address"
                    :prepend-inner-icon="'mdi-email-outline'"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  type="submit"
                  rounded
                  block
                  x-large
                  light
                  class="j-btn tw-bg-chartreuse tw-tracking-normal"
                  :disabled="!valid"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </v-form>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <a
          href="https://www.letsjetty.com/blog/how-to-add-lets-jetty-as-an-app-on-your-home-screen?utm_source=login-screen&utm_medium=app"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="j-web-app tw-mt-8 tw-flex tw-p-8">
            <img
              src="@/assets/lets-jetty-mark.jpeg"
              alt="Lets Jetty"
              class="tw-mr-4 tw-bg-chartreuse tw-w-9 tw-h-9"
            />
            <div>
              <p
                class="tw-text-sm tw-text-charcoal tw-text-left tw-leading-5 tw-mb-2"
              >
                Let’s Jetty is a web app. You can use it on any device.
              </p>

              <p
                class="tw-underline tw-text-left tw-text-dark-blue tw-uppercase tw-text-11 tw-font-bold tw-tracking-tight tw-m-0"
              >
                Add it to your home screen
              </p>
            </div>
          </div>
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "SignupOrLoginForm",

  components: {
    ValidationProvider,
    ValidationObserver
  },

  data() {
    return {
      email: null
    };
  },

  computed: {
    valid() {
      return this.validEmail();
    }
  },

  methods: {
    validEmail() {
      const re = /\S+@\S+\.\S+/;
      return re.test(this.email);
    },
    progressSignupOrLogin() {
      this.$refs.observer.validate();
      const email = this.email.toLowerCase();
      this.$store
        .dispatch("auth-user-check/find", {
          query: {
            email
          }
        })
        .then((res) => {
          const ref = this.$route.query.ref;

          if (res.total > 0) {
            this.$router.push({
              name: "LoginRoute",
              query: { email, ref }
            });
          } else {
            this.$router.push({
              name: "SignupRoute",
              query: { email, ref }
            });
          }
        });
    }
  },
  async beforeMount() {
    //in case of redirecting to login before clicking logout
    await this.$store.dispatch("auth/logout");
  }
};
</script>

<style lang="scss" scoped>
.signup-or-login-form
  .v-form
  .v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
  background-color: #f7f6f5;
}

.signup-or-login-form .v-form .v-text-field--outlined fieldset {
  border-color: #f7f6f5;
}

.signup-or-login-form .v-btn__content {
  color: #2c3e50;
  text-transform: none;
}

.j-web-app {
  border-radius: 9px;
  border: 1px solid var(--Primary-Brand-Color, #e6ffa1);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.21);
}
</style>
